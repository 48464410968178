import React from "react";
import { useStaticQuery } from "gatsby";
const Questions = () => {
  const {
    Questions: {
      frontmatter: { questions },
    },
  } = useStaticQuery(graphql`
    query About {
      Questions: markdownRemark(fileAbsolutePath: { regex: "/about_page/" }) {
        frontmatter {
          questions {
            title
            image {
              publicURL
            }
            discription
          }
        }
      }
    }
  `);
  return (
    <div className="py-12 max-w-6xl mx-auto px-5 md:px-8">
      {questions.map((question, i) => {
        const { image, title, discription } = question;
        if (i % 2 === 0) {
          return (
            <div className="flex flex-wrap justify-between my-16 items-center md:mb-32">
              <div className="w-1/2 mx-auto md:w-1/4 mb-4 md:mb-0">
                <img src={image.publicURL} alt="wizard-img" />
              </div>
              <div className="w-full md:w-2/3 my-8">
                <h2 className="text-2xl md:text-4xl text-blackish font-bold">
                  {title}
                </h2>
                <div className="mt-8 text-textcolor text-md md:text-lg">
                  {discription}
                </div>

                <div className={i === 2 ? "block mt-12" : "hidden"}>
                  <a
                    rel="noreferrer noopener"
                    href="https://elementsoftworks.co.uk/"
                    target="_blank"
                  >
                    <button className="capitalize focus:outline-none bg-theme text-white btn-purple-on-white text-sm rounded-md w-3/5 md:w-2/5 lg:w-1/3 py-3 block md:inline mx-auto mb-4 md:mb-0">
                      element softworks website
                    </button>
                  </a>
                  <a
                    href="https://elementsoftworks.co.uk/merlin.pdf"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <button className="border focus:outline-none text-theme hover:text-buttonpurplehover border border-theme w-3/5 md:w-2/5 lg:w-1/4 py-3 rounded text-sm ml-8 block md:inline ml-auto mr-auto md:ml-8">
                      Download brochure
                    </button>
                  </a>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="flex flex-wrap-reverse justify-center my-16 items-center">
              <div className="w-full md:w-2/3 my-8">
                <h2 className="text-2xl md:text-4xl text-blackish font-bold">
                  {title}
                </h2>
                <div className="mt-8 lg:pr-24 text-textcolor text-md md:text-lg">
                  {discription}
                </div>
              </div>
              <div className="w-1/2 mx-auto md:w-1/4 mb-4 md:mb-0">
                <img src={image.publicURL} alt="wizard-img" />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default Questions;
