import React from "react";
import "../styles/style.scss";
import Layout from "../components/layout";
import Questions from "../views/about/questions";
import Aims from "../views/about/aims";
import SEO from "../components/seo";

const App = () => {
  return (
    <Layout>
      <SEO
        seo={{
          title: "About Merlin Panel",
          description:
            "Merlin Panel is a platform built for small businesses to manage their websites as easily and effectively through a dashboard at an affordable monthly price.",
          previewURL: "https://merlinpanel.com/images/about/preview.png",
        }}
      />
      <Aims />
      <Questions />
    </Layout>
  );
};

export default App;
