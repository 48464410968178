import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
const Aims = () => {
  const {
    About: {
      frontmatter: { image, discription, aims },
    },
  } = useStaticQuery(graphql`
    query Aims {
      About: markdownRemark(fileAbsolutePath: { regex: "/about_page/" }) {
        frontmatter {
          discription
          image {
            publicURL
          }
          aims {
            text
          }
        }
      }
    }
  `);

  return (
    <div className="mt-40 max-w-6xl mx-auto px-5 md:px-8">
      <div className="w-full md:w-4/5 mx-auto flex flex-col items-center py-8">
        <div className="w-1/2 md:w-1/3 lg:w-1/5">
          <img
            alt={`about-image`}
            style={{ width: "100%" }}
            src={image.publicURL}
          />
        </div>
        <h2 className="text-3xl md:text-6xl text-blackish mt-16 font-bold">
          About Merlin Panel
        </h2>
        <p className="text-textcolor text-md  md:text-lg mt-12 md:text-center">
          {discription}
        </p>
      </div>
      <div className="w-full mt-16">
        <h2 className="text-2xl md:text-4xl text-blackish font-bold text-center ">
          Our Aims
        </h2>
        <div className="flex flex-wrap mt-12">
          {aims.map((aim, index) => {
            const { text } = aim;
            return (
              <div className="aim-card md:pr-5 w-full md:w-1/2 ">
                <div className="content w-full md:text-center flex flex-col items-center justify-center md:justtify-start text-md md:text-lg text-textcolor p-10 lg:p-20">
                  <div className="number text-3xl text-white items-center md:pb-2  justify-center flex">
                    {index + 1}
                  </div>
                  <div className="mt-12"> {text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Aims;
